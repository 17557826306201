import axios, { AxiosResponse } from '@/api/api';
import {
    AccountRegistrationTypes,
    BasicRegistrationPayload, Customer as CustomerType,
    FullRegistrationPayload,
    UpdateLoginInfoPayload,
    UpdateLoginInfoResponse,
    UpdatePersonalDataPayload,
    UpdatePersonalDataResponse,
}                               from '@/types/account';
import getRoute                 from '@/utils/routing';

class CustomerApi {
    async login(email: string, password: string, authType: AccountRegistrationTypes, inCheckoutProcess: boolean) {
        return axios.post<CustomerType>(
            getRoute('api_v1_account_login', { checkout: inCheckoutProcess ? 1 : 0 }),
            {
                type:     authType,
                mode:     'login',
                email:    email,
                password: password,
            },
            { withCredentials: true },
        );
    }

    async googleAuth(credentials: string) {
        return axios.post(
            getRoute('api_v1_account_google_auth'),
            { token: credentials },
            { withCredentials: true },
        );
    }

    async logout() {
        return axios.get(
            getRoute('api_v1_account_logout'),
        );
    }

    async register(payload: BasicRegistrationPayload | FullRegistrationPayload) {
        return axios.post<CustomerType>(
            getRoute('api_v1_account_register'),
            payload,
            { withCredentials: true },
        );
    }

    async magicLink(email: string) {
        return axios.post(
            getRoute('api_v1_account_magic_link'),
            { email },
        );
    }

    async resetPassword(email: string) {
        return axios.post(
            getRoute('api_v1_account_reset_password'),
            { email },
        );
    }

    async checkPasswordRecoveryCode(passwordRecoveryCode: string): Promise<AxiosResponse<string>> {
        return axios.post(
            getRoute('api_v1_account_check_password_recovery_code', { passwordRecoveryCode }),
        );
    }

    async checkPayPalSetPasswordCode(passwordRecoveryCode: string): Promise<AxiosResponse<{ userEmail: string }>> {
        return axios.post(
            getRoute('api_v1_account_check_paypal_password_set_code', { passwordRecoveryCode }),
        );
    }

    async changePassword(password: string, passwordConfirm: string, recoveryCode: string) {
        return axios.post(
            getRoute('api_v1_account_set_password'),
            { password, passwordConfirm, recoveryCode },
        );
    }

    async updatePersonalData(payload: UpdatePersonalDataPayload) {
        return axios.post<UpdatePersonalDataResponse>(
            getRoute('api_v1_account_update_personal_data'),
            payload,
        );
    }

    async updateLoginInfo(payload: UpdateLoginInfoPayload) {
        return axios.post<UpdateLoginInfoResponse>(
            getRoute('api_v1_account_update_login_data'),
            payload,
        );
    }

    async removeAccount(password: string) {
        return axios.post(
            getRoute('api_v1_account_remove_account'),
            { password },
        );
    }

    async getCustomer() {
        return axios.get<CustomerType>(
            getRoute('api_v1_account_get_customer'),
        );
    }

    async newsletterSignup(email: string) {
        return axios.post<string>(
            getRoute('api_v1_newsletter_signup'),
            { email },
        );
    }
}

export default (new CustomerApi());
